const generatePowerInvoice = (data, formData, number, revoked) => {
  const year = formData.date.slice(-4);
  const language = data.attributes.owners.data[0].attributes.Language;

  const hasObservations = formData.rows.some((item) => item.observations);

  const items = formData.rows.map((row) =>
    [
      row.ref,
      row.previousReading,
      row.previousDate,
      row.currentReading,
      row.currentDate,
      (Number(row.currentReading) - Number(row.previousReading)).toFixed(2),
      `${row.unitValue}€`,
      `${(
        (Number(row.currentReading) - Number(row.previousReading)) *
        Number(row.unitValue)
      ).toFixed(2)}€`,
      hasObservations && row.observations,
    ]
      .filter((item) => item !== false)
      .map((value) => ({
        text: value,
        style: 'mainTable',
      }))
  );

  const total = items.reduce(
    (acc, item) => acc + Number(item[7].text.slice(0, -1)),
    0
  );

  return {
    totalValue: total,
    pdfMakeObject: {
      styles: {
        removePadding: {
          margin: [-5, 0, 0, 0],
        },
        mainTable: {
          fontSize: 9,
        },
      },
      content: [
        {
          columns: [
            [
              {
                text: data.attributes.condo.data.attributes.Name,
              },
              {
                text: data.attributes.condo.data.attributes.Address,
              },
              {
                text:
                  language === 'PT'
                    ? `Contribuinte nº ${data.attributes.condo.data.attributes.VAT}`
                    : `VAT: ${data.attributes.condo.data.attributes.VAT}`,
              },
            ],
            [
              {
                text: `\n\n${data.attributes.owners.data[0].attributes.Name}`,
              },
              {
                text: data.attributes.owners.data[0].attributes.Address,
              },
              {
                text:
                  language === 'PT'
                    ? 'Válido como recibo após boa cobrança'
                    : 'Valid as receipt after good payment confirmation',
                bold: true,
                italics: true,
              },
            ],
          ],
        },
        {
          text: '\n\n',
        },
        {
          text:
            language === 'PT'
              ? `Aviso Nº ${year}/${number}${revoked ? ` (ANULADO)` : ``}`
              : `Notice No. ${year}/${number}${revoked ? ` (REVOKED)` : ``}`,
          bold: true,
        },
        {
          margin: [0, 20, 0, 8],
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', '*', 'auto', 'auto'],
            body: [
              [
                {
                  text: language === 'PT' ? 'V/Nº Contrib.' : 'VAT number',
                  bold: true,
                },
                { text: language === 'PT' ? 'Fracção' : 'Unit', bold: true },
                {
                  text: language === 'PT' ? 'Designação' : 'Designation',
                  bold: true,
                },
                { text: language === 'PT' ? 'Data' : 'Date', bold: true },
                { text: 'Via', bold: true },
              ],
              [
                data.attributes.owners.data[0].attributes.VAT
                  ? data.attributes.owners.data[0].attributes.VAT
                  : '-',
                data.attributes.Identifier,
                data.attributes.Designation,
                formData.date,
                'Original',
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          text: formData.title,
          bold: true,
        },
        {
          text: '\n',
        },
        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              hasObservations && 'auto',
            ].filter(Boolean),
            body: [
              [
                {
                  text: '',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text:
                    language === 'PT' ? 'Leitura anterior' : 'Previous reading',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text: language === 'PT' ? 'Data' : 'Date',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text:
                    language === 'PT' ? 'Leitura actual' : 'Current reading',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text: language === 'PT' ? 'Data' : 'Date',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text: language === 'PT' ? 'Consumo' : 'Consumption',
                  bold: true,
                  style: 'mainTable',
                },
                {
                  text: language === 'PT' ? 'Valor/uni' : 'Unit/value',
                  bold: true,
                  style: 'mainTable',
                },
                { text: 'Total', bold: true, style: 'mainTable' },
                hasObservations && {
                  text: language === 'PT' ? 'Obs.' : 'Notes',
                  bold: true,
                  style: 'mainTable',
                },
              ].filter(Boolean),
              ...items,
            ],
          },
        },
        {
          text: '\n',
        },
        {
          table: {
            body: [
              [
                {
                  border: [false, false, false, false],
                  text:
                    language === 'PT'
                      ? 'TOTAL A REGULARIZAR:'
                      : 'TOTAL AMOUNT:',
                  style: 'removePadding',
                  bold: true,
                },
                `${total.toFixed(2)} €`,
              ],
            ],
          },
        },
        {
          text: [`\nIBAN: ${data.attributes.condo.data.attributes.IBAN}\n\n`],
          bold: true,
        },
        {
          text: [
            { text: language === 'PT' ? 'NOTA: ' : 'NOTE: ', bold: true },
            language === 'PT'
              ? 'O pagamento deverá ser efectuado sempre com cheque traçado à ordem do condomínio ou por transferência bancária\nA Administraçao não se responsabiliza por pagamentos em dinheiro ou cheques ao portador\n\n'
              : 'Payment must always be made with a crossed cheque to the condominium or by bank transfer.\nThe Administration is not responsible for payments in cash or bearer cheques.\n\n',
          ],
          italics: true,
          decoration: 'underline',
        },
        {
          text: [
            {
              text:
                language === 'PT'
                  ? 'IDENTIFICAR POR FAVOR A FRACÇÃO CORRESPONDENTE NA ORDEM DE TRANSFERÊNCIA\n\n'
                  : 'PLEASE INDICATE THE CORRESPONDING UNIT IN THE TRANSFER ORDER\n\n',
              bold: true,
            },
            {
              text:
                language === 'PT'
                  ? 'Documento processado por computador\n\n'
                  : 'Document processed by computer\n\n',
              fontSize: 12,
            },
          ],
        },
      ],
    },
  };
};

export default generatePowerInvoice;
