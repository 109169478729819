import { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useRequest from 'utils/useRequest';
import Markdown from 'utils/Markdown';
import Loading from 'components/Loading';

import QuotesModal from './Condo/QuotesModal';
import InvoiceModal from './Condo/InvoiceModal';
import PaymentModal from './Condo/PaymentModal';
import MovementsModal from './Condo/MovementsModal';

const typesToIgnore = ['power'];

const financial = (x) => Number.parseFloat(x.toFixed(8));

const currentYear = new Date().getFullYear();

const getInvoiceDate = (invoice) => {
  const year = invoice.attributes.Data.year;
  let month = 0;
  for (let index = 1; index <= 12; index++) {
    if (invoice.attributes.Data.formData[index]) {
      month = index;
    }
  }
  return {
    year: month ? year : 0,
    month,
  };
};

const Condo = () => {
  const [ignoreTypes, setIgnoreTypes] = useState(true);
  const [quotesModal, setQuotesModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState();
  const [paymentModal, setPaymentModal] = useState();
  const [movementsModal, setMovementsModal] = useState();
  const [selectedUnits, setSelectedUnits] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({
    identifier: true,
    designation: true,
    permille: false,
    quotes: true,
    owners: true,
    balance: true,
    lastInvoice: true,
  });
  const { id } = useParams();

  const { request, data, loading } = useRequest({
    url: `condos/${id}?populate[units][populate][rates]=*&populate[units][populate][owners]=*&populate[units][populate][transactions]=*&populate[units][populate][invoices]=*`,
    method: 'GET',
  });

  const units = useMemo(() => {
    if (data) {
      return data.data.attributes.units.data
        .sort((a, b) => a.id - b.id)
        .map((unit) => {
          const invoices = unit.attributes.invoices.data.reduce(
            (acc, invoice) => {
              if (
                (ignoreTypes &&
                  typesToIgnore.includes(invoice.attributes.Data.type)) ||
                invoice.attributes.Revoked
              ) {
                return acc;
              }
              acc.push({
                id: `invoice-${invoice.id}`,
                sourceId: invoice.id,
                type: 'invoice',
                internalType: invoice.attributes.Data.type,
                date: invoice.attributes.Date,
                value: invoice.attributes.Data.totalValue,
                Data: invoice.attributes.Data,
                lastMonth: getInvoiceDate(invoice),
              });
              return acc;
            },
            []
          );
          const transactions = unit.attributes.transactions.data.reduce(
            (acc, transaction) => {
              if (
                (ignoreTypes &&
                  typesToIgnore.includes(transaction.attributes.Type)) ||
                transaction.attributes.Revoked
              ) {
                return acc;
              }
              acc.push({
                id: `transaction-${transaction.id}`,
                sourceId: transaction.id,
                type: 'transaction',
                internalType: transaction.attributes.Type,
                date: transaction.attributes.Date,
                value: transaction.attributes.Value,
                notes: transaction.attributes.Notes,
              });
              return acc;
            },
            []
          );
          const movements = invoices
            .concat(transactions)
            .sort((a, b) => new Date(b.date) > new Date(a.date));
          const balance = movements.reduce((acc, movement) => {
            if (movement.type === 'invoice') {
              acc -= movement.value;
            }
            if (movement.type === 'transaction') {
              acc += movement.value;
            }
            return financial(acc);
          }, 0);
          let lastInvoice;
          if (invoices.length) {
            lastInvoice = invoices
              .filter(
                (invoice) => !typesToIgnore.includes(invoice.internalType)
              )
              .sort((a, b) => {
                return `${b.lastMonth.year}_${b.lastMonth.month
                  ?.toString()
                  .padStart(2, '0')}`.localeCompare(
                  `${a.lastMonth.year}_${a.lastMonth.month
                    ?.toString()
                    .padStart(2, '0')}`
                );
              })[0].lastMonth;
          }
          return {
            ...unit,
            lastInvoice,
            balance: Number(balance).toFixed(2),
            quote: unit.attributes.rates.data.find(
              (rate) => rate.attributes.Year === currentYear
            )?.attributes.Value,
          };
        });
    }
    return [];
  }, [data, ignoreTypes]);

  const selectedUnitsCount = useMemo(
    () => Object.values(selectedUnits).filter((item) => item).length,
    [selectedUnits]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <section className="section">
        <div className="container content">
          <h1 className="title">{data.data.attributes.Name}</h1>
          <dl>
            <dt>
              <strong>Morada:</strong>
            </dt>
            <dd>
              <Markdown content={data.data.attributes.Address} />
            </dd>
            <dt>
              <strong>NIF:</strong>
            </dt>
            <dd>{data.data.attributes.VAT}</dd>
            <dt>
              <strong>IBAN:</strong>
            </dt>
            <dd>{data.data.attributes.IBAN}</dd>
          </dl>
          <h2>Fracções</h2>
          <button
            className="button is-small is-danger"
            onClick={() => {
              setQuotesModal(true);
            }}
          >
            Editar quotas de {currentYear}
          </button>
          <hr />
          <h3>Opções</h3>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={ignoreTypes}
                onChange={() => {
                  setIgnoreTypes((prev) => !prev);
                }}
              />{' '}
              Ignorar movimentos de electricidade para cálculo do saldo
            </label>
          </div>
          <h3>Colunas visíveis</h3>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={visibleColumns.permille}
                onChange={() => {
                  setVisibleColumns((prev) => ({
                    ...prev,
                    permille: !prev.permille,
                  }));
                }}
              />{' '}
              Permilagem
            </label>
          </div>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={visibleColumns.quotes}
                onChange={() => {
                  setVisibleColumns((prev) => ({
                    ...prev,
                    quotes: !prev.quotes,
                  }));
                }}
              />{' '}
              Quotas mensais
            </label>
          </div>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={visibleColumns.owners}
                onChange={() => {
                  setVisibleColumns((prev) => ({
                    ...prev,
                    owners: !prev.owners,
                  }));
                }}
              />{' '}
              Proprietários
            </label>
          </div>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={visibleColumns.balance}
                onChange={() => {
                  setVisibleColumns((prev) => ({
                    ...prev,
                    balance: !prev.balance,
                  }));
                }}
              />{' '}
              Saldo
            </label>
          </div>
          <div>
            <label className="checkbox">
              <input
                type="checkbox"
                checked={visibleColumns.lastInvoice}
                onChange={() => {
                  setVisibleColumns((prev) => ({
                    ...prev,
                    lastInvoice: !prev.lastInvoice,
                  }));
                }}
              />{' '}
              Último aviso
            </label>
          </div>
          <hr />
          <button
            disabled={!selectedUnitsCount}
            className="button is-primary is-small"
            onClick={() => {
              const selectedUnitsArray = Object.entries(selectedUnits).reduce(
                (acc, [key, value]) => {
                  if (value) {
                    acc.push(key);
                  }
                  return acc;
                },
                []
              );
              setInvoiceModal(selectedUnitsArray);
            }}
          >
            <span>Criar avisos para as fracções seleccionadas</span>
          </button>
          <br />
          <br />
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
              <tr>
                <td>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={selectedUnitsCount === units.length}
                      onChange={() => {
                        if (selectedUnitsCount === units.length) {
                          setSelectedUnits({});
                        } else {
                          setSelectedUnits(
                            units.reduce((acc, { id }) => {
                              acc[id] = true;
                              return acc;
                            }, {})
                          );
                        }
                      }}
                    />
                  </label>
                </td>
                <th style={{ whiteSpace: 'nowrap' }}>Fracção</th>
                <th style={{ whiteSpace: 'nowrap' }}>Designação</th>
                {visibleColumns.permille && (
                  <th style={{ whiteSpace: 'nowrap' }}>Permilagem</th>
                )}
                {visibleColumns.quotes && (
                  <th style={{ whiteSpace: 'nowrap' }}>
                    Quotas mensais ({currentYear})
                  </th>
                )}
                {visibleColumns.owners && (
                  <th style={{ whiteSpace: 'nowrap' }}>Proprietário(s)</th>
                )}
                {visibleColumns.balance && (
                  <th style={{ whiteSpace: 'nowrap' }}>Saldo</th>
                )}
                {visibleColumns.lastInvoice && (
                  <th style={{ whiteSpace: 'nowrap' }}>Último aviso</th>
                )}
                <th style={{ whiteSpace: 'nowrap' }}>Acções</th>
              </tr>
            </thead>
            <tbody>
              {units.map(
                ({ id, attributes: unit, quote, balance, lastInvoice }) => (
                  <tr key={id}>
                    <td style={{ verticalAlign: 'middle' }}>
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          checked={Boolean(selectedUnits[id])}
                          onChange={() => {
                            setSelectedUnits((prev) => ({
                              ...prev,
                              [id]: !prev[id],
                            }));
                          }}
                        />
                      </label>
                    </td>
                    <th style={{ verticalAlign: 'middle' }}>
                      {unit.Identifier}
                    </th>
                    <td style={{ verticalAlign: 'middle' }}>
                      {unit.Designation}
                    </td>
                    {visibleColumns.permille && (
                      <td style={{ verticalAlign: 'middle' }}>
                        {unit.Permille}
                      </td>
                    )}
                    {visibleColumns.quotes && (
                      <td
                        style={{
                          verticalAlign: 'middle',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {quote ? `${quote} €` : '-'}
                      </td>
                    )}
                    {visibleColumns.owners && (
                      <td style={{ verticalAlign: 'middle' }}>
                        {unit.owners.data.map((owner) => (
                          <div key={owner.id}>{owner.attributes.Name}</div>
                        ))}
                      </td>
                    )}
                    {visibleColumns.balance && (
                      <td
                        style={{
                          verticalAlign: 'middle',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {balance} €
                      </td>
                    )}
                    {visibleColumns.lastInvoice && (
                      <td style={{ verticalAlign: 'middle' }}>
                        {lastInvoice
                          ? `${lastInvoice.month ? lastInvoice.month : '?'}/${
                              lastInvoice.year ? lastInvoice.year : '?'
                            }`
                          : '-'}
                      </td>
                    )}
                    <td style={{ verticalAlign: 'middle' }}>
                      <div className="buttons" style={{ flexWrap: 'inherit' }}>
                        <button
                          disabled={!quote}
                          className="button is-primary is-small"
                          onClick={() => {
                            setInvoiceModal([id]);
                          }}
                        >
                          <span className="icon">
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          <span>Aviso</span>
                        </button>
                        <button
                          className="button is-info is-small"
                          onClick={() => {
                            setPaymentModal({
                              id,
                              unit: unit.Identifier,
                              owners: unit.owners.data,
                            });
                          }}
                        >
                          <span className="icon">
                            <i className="fa-solid fa-plus"></i>
                          </span>
                          <span>Pagamento</span>
                        </button>
                        <button
                          className="button is-success is-small"
                          onClick={() => {
                            setMovementsModal({ id, unit: unit.Identifier });
                          }}
                        >
                          <span className="icon">
                            <i className="fa-solid fa-eye"></i>
                          </span>
                          <span>Movimentos</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </section>
      {quotesModal && <QuotesModal units={units} setVisible={setQuotesModal} />}
      {invoiceModal && (
        <InvoiceModal units={invoiceModal} setVisible={setInvoiceModal} />
      )}
      {paymentModal && (
        <PaymentModal unit={paymentModal} setVisible={setPaymentModal} />
      )}
      {movementsModal && (
        <MovementsModal
          unit={movementsModal}
          setVisible={setMovementsModal}
          onChange={() => {
            request();
          }}
        />
      )}
    </>
  );
};

export default Condo;
