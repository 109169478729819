import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'bulma/css/bulma.min.css';
import '@fortawesome/fontawesome-free/js/all';

import App from './App';

import { StateProvider } from 'state/State';
import { initialState, MainReducer } from 'state/MainReducer';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <StateProvider initialState={initialState} reducer={MainReducer}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StateProvider>
);
