import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

import { useStateValue } from 'state/State';
import Loading from 'components/Loading';
import Login from 'views/Login';
import useRequest from 'utils/useRequest';

const Auth = (props) => {
  const [loading, setLoading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const {
    data,
    loading: requesting,
    request,
  } = useRequest({
    url: 'users/me',
    method: 'GET',
    callback: true,
  });

  useEffect(() => {
    if (data) {
      dispatch({
        type: 'user.login',
        payload: data,
      });
    }
  }, [data, dispatch]);

  useEffect(() => {
    const token = Cookies.get('AUTH-TOKEN');
    if (!user && token) {
      request();
    } else {
      setLoading(false);
    }
  }, [user, request]);

  if (loading || requesting) {
    return <Loading type="full" />;
  }

  if (!user) {
    return <Login />;
  }

  return props.children;
};

export default Auth;
