import { months } from '../InvoiceModal/CommonInvoiceForm';

const generatePage = (
  data,
  selectedMonths,
  currentRate,
  language,
  year,
  number,
  revoked,
  quotesText,
  formData,
  owner,
  pageBreak
) => {
  const ownersWithShares = data.attributes.owners_shares?.length > 1;
  let ownerTotal = selectedMonths.length * currentRate;
  let ownerRate = currentRate;
  let invoiceNumber = number;
  if (ownersWithShares) {
    ownerRate = (ownerRate * data.attributes.owners_shares[owner]) / 100;
    ownerTotal = (ownerTotal * data.attributes.owners_shares[owner]) / 100;
    invoiceNumber = `${number}-${owner + 1}`;
  }

  const items = quotesText
    ? [
        [
          quotesText,
          selectedMonths.length,
          `${ownerRate.toFixed(2)} €`,
          `${ownerTotal.toFixed(2)} €`,
        ],
      ]
    : [];

  if (formData.custom) {
    let customValue = Number(formData.customValue);
    if (ownersWithShares) {
      customValue =
        (Number(formData.customValue) * data.attributes.owners_shares[owner]) /
        100;
    }
    items.unshift(
      quotesText
        ? [formData.customDescription, '-', '-', `${customValue.toFixed(2)} €`]
        : [formData.customDescription, `${customValue.toFixed(2)} €`]
    );
    ownerTotal += customValue;
  }

  if (formData.fix) {
    const previousRate = data.attributes.rates.data.find(
      (rate) => rate.attributes.Year === year - 1
    )?.attributes.Value;
    let difference = previousRate ? currentRate - previousRate : 0;
    if (ownersWithShares) {
      difference = (difference * data.attributes.owners_shares[owner]) / 100;
    }
    if (difference) {
      const monthsToFix = selectedMonths[0] - 1;
      const totalToFix = monthsToFix * difference;
      items.unshift([
        language === 'PT'
          ? `Acerto de quotas de acordo com A.G.O.${
              formData.ago ? ` de ${formData.ago}` : ''
            }`
          : `Adjustment fee according to ${year}'s budget`,
        monthsToFix,
        `${difference.toFixed(2)} €`,
        `${totalToFix.toFixed(2)} €`,
      ]);
      ownerTotal += totalToFix;
    }
  }

  return [
    {
      columns: [
        [
          {
            text: data.attributes.condo.data.attributes.Name,
          },
          {
            text: data.attributes.condo.data.attributes.Address,
          },
          {
            text:
              language === 'PT'
                ? `Contribuinte nº ${data.attributes.condo.data.attributes.VAT}`
                : `VAT: ${data.attributes.condo.data.attributes.VAT}`,
          },
        ],
        [
          {
            text: `\n\n${data.attributes.owners.data[owner].attributes.Name}`,
          },
          {
            text: data.attributes.owners.data[owner].attributes.Address,
          },
          {
            text:
              language === 'PT'
                ? 'Válido como recibo após boa cobrança'
                : 'Valid as receipt after good payment confirmation',
            bold: true,
            italics: true,
          },
        ],
      ],
    },
    {
      text: '\n\n',
    },
    {
      text:
        language === 'PT'
          ? `Aviso Nº ${year}/${invoiceNumber}${revoked ? ` (ANULADO)` : ``}`
          : `Notice No. ${year}/${invoiceNumber}${revoked ? ` (REVOKED)` : ``}`,
      bold: true,
    },
    {
      margin: [0, 20, 0, 8],
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', '*', 'auto', 'auto'],
        body: [
          [
            {
              text: language === 'PT' ? 'V/Nº Contrib.' : 'VAT number',
              bold: true,
            },
            { text: language === 'PT' ? 'Fracção' : 'Unit', bold: true },
            {
              text: language === 'PT' ? 'Designação' : 'Designation',
              bold: true,
            },
            { text: language === 'PT' ? 'Data' : 'Date', bold: true },
            { text: 'Via', bold: true },
          ],
          [
            data.attributes.owners.data[owner].attributes.VAT
              ? data.attributes.owners.data[owner].attributes.VAT
              : '-',
            data.attributes.Identifier,
            data.attributes.Designation,
            formData.date,
            'Original',
          ],
        ],
      },
    },
    {
      text: '\n\n',
    },
    {
      layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: quotesText ? ['*', 'auto', 'auto', 'auto'] : ['*', 'auto'],
        body: quotesText
          ? [
              [
                {
                  text: language === 'PT' ? 'Descrição' : 'Description',
                  bold: true,
                },
                {
                  text: language === 'PT' ? 'Meses' : 'Months',
                  bold: true,
                },
                {
                  text: language === 'PT' ? 'Quota Mensal' : 'Monthly Fee',
                  bold: true,
                },
                { text: 'Total', bold: true },
              ],
              ...items,
            ]
          : [
              [
                {
                  text: language === 'PT' ? 'Descrição' : 'Description',
                  bold: true,
                },
                { text: 'Total', bold: true },
              ],
              ...items,
            ],
      },
    },
    {
      text: '\n',
    },
    {
      table: {
        body: [
          [
            {
              border: [false, false, false, false],
              text:
                language === 'PT' ? 'TOTAL A REGULARIZAR:' : 'TOTAL AMOUNT:',
              style: 'removePadding',
              bold: true,
            },
            `${ownerTotal.toFixed(2)} €`,
          ],
        ],
      },
    },
    {
      text: [`\nIBAN: ${data.attributes.condo.data.attributes.IBAN}\n\n`],
      bold: true,
    },
    {
      text: [
        { text: language === 'PT' ? 'NOTA: ' : 'NOTE: ', bold: true },
        language === 'PT'
          ? 'O pagamento do condomínio deverá ser efectuado sempre com cheque traçado à ordem do condomínio ou por transferência bancária\nA Administraçao não se responsabiliza por pagamentos em dinheiro ou cheques ao portador\n\n'
          : 'Payment for the condominium must always be made with a crossed cheque to the condominium or by bank transfer.\nThe Administration is not responsible for payments in cash or bearer cheques.\n\n',
      ],
      italics: true,
      decoration: 'underline',
    },
    {
      text: [
        {
          text:
            language === 'PT'
              ? 'IDENTIFICAR POR FAVOR A FRACÇÃO CORRESPONDENTE NA ORDEM DE TRANSFERÊNCIA\n\n'
              : 'PLEASE INDICATE THE CORRESPONDING UNIT IN THE TRANSFER ORDER\n\n',
          bold: true,
        },
        {
          text:
            language === 'PT'
              ? 'Documento processado por computador\n\n'
              : 'Document processed by computer\n\n',
          fontSize: 12,
        },
        {
          text:
            language === 'PT'
              ? 'Isento de IVA Artigo 9º CIVA'
              : 'VAT exempt Art. 9 CIVA',
          bold: true,
        },
      ],
      pageBreak: pageBreak ? 'after' : undefined,
    },
  ];
};

const generateInvoice = (data, formData, year, number, revoked) => {
  const language = data.attributes.owners.data[0].attributes.Language;

  const selectedMonths = months.reduce((acc, item) => {
    if (formData[item.value]) {
      acc.push(item.value);
    }
    return acc;
  }, []);

  let quotesText;
  if (selectedMonths.length > 1) {
    quotesText =
      language === 'PT'
        ? `${months[selectedMonths[0] - 1].name} a ${
            months[selectedMonths[selectedMonths.length - 1] - 1].name
          } de ${year}`
        : `${months[selectedMonths[0] - 1].nameEn} to ${
            months[selectedMonths[selectedMonths.length - 1] - 1].nameEn
          } ${year}`;
  } else if (selectedMonths.length === 1) {
    quotesText =
      language === 'PT'
        ? `${months[selectedMonths[0] - 1].name} de ${year}`
        : `${months[selectedMonths[0] - 1].nameEn} ${year}`;
  }

  const currentRate = data.attributes.rates.data.find(
    (rate) => rate.attributes.Year === year
  )?.attributes.Value;

  let total = selectedMonths.length * currentRate;

  if (formData.custom) {
    const customValue = Number(formData.customValue);
    total += customValue;
  }

  if (formData.fix) {
    const previousRate = data.attributes.rates.data.find(
      (rate) => rate.attributes.Year === year - 1
    )?.attributes.Value;
    const difference = previousRate ? currentRate - previousRate : 0;
    if (difference) {
      const monthsToFix = selectedMonths[0] - 1;
      const totalToFix = monthsToFix * difference;
      total += totalToFix;
    }
  }

  let content;
  if (data.attributes.owners_shares?.length > 1) {
    data.attributes.owners_shares.forEach((_, index) => {
      content = !content
        ? generatePage(
            data,
            selectedMonths,
            currentRate,
            language,
            year,
            number,
            revoked,
            quotesText,
            formData,
            0,
            true
          )
        : content.concat(
            generatePage(
              data,
              selectedMonths,
              currentRate,
              language,
              year,
              number,
              revoked,
              quotesText,
              formData,
              index,
              index === data.attributes.owners_shares.length - 1 ? false : true
            )
          );
    });
  } else {
    content = generatePage(
      data,
      selectedMonths,
      currentRate,
      language,
      year,
      number,
      revoked,
      quotesText,
      formData,
      0,
      false
    );
  }

  return {
    totalValue: total,
    pdfMakeObject: {
      styles: {
        removePadding: {
          margin: [-5, 0, 0, 0],
        },
      },
      content,
    },
  };
};

export default generateInvoice;
