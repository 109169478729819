import { useState, useMemo } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import useRequest from 'utils/useRequest';

import generatePowerInvoice from '../utils/generatePowerInvoice';
import PowerInvoice from './PowerInvoice';
import UnitDetails from './UnitDetails';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const currentYear = new Date().getFullYear();

const rowObject = {
  ref: 'Tarifa Única - 00.00/24.00h',
  previousReading: '0',
  previousDate: '',
  currentReading: '0',
  currentDate: '',
  consumption: undefined,
  unitValue: '0',
  totalValue: undefined,
  observations: '',
};

const defaultFormData = {
  date: new Date().toLocaleDateString('pt-PT'),
  title: 'Consumo Electricidade p/ Carregamento Viaturas Eléctricas',
  rows: [{ ...rowObject }],
};

const PowerInvoiceForm = ({ units, setVisible, data, preview, setPreview }) => {
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  const { request: save } = useRequest({
    url: `invoices`,
    method: 'POST',
    callback: true,
  });

  const selectedUnits = useMemo(() => {
    const selectedUnitsObject = units.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
    return data?.length
      ? data?.filter((item) => selectedUnitsObject[item.id])
      : [data];
  }, [units, data]);

  const currentYearInvoiceCount = useMemo(
    () =>
      selectedUnits.reduce((acc, item) => {
        const invoices = item.attributes.invoices.data.filter(
          (invoice) =>
            invoice.attributes.Date.slice(0, 4) === currentYear.toString()
        );
        acc[item.id] = invoices.length;
        return acc;
      }, {}),
    [selectedUnits]
  );

  const isValid = useMemo(() => {
    let valid = true;
    formData.rows.forEach((row) => {
      if (
        !row.ref ||
        !row.previousDate ||
        !row.currentReading ||
        !row.currentDate ||
        !row.unitValue ||
        row.previousDate >= row.currentDate ||
        Number(row.currentReading).toString() !== row.currentReading ||
        Number(row.previousReading).toString() !== row.previousReading ||
        Number(row.currentReading) < Number(row.previousReading)
      ) {
        valid = false;
      }
    });
    return valid;
  }, [formData]);

  return (
    <>
      <section className="modal-card-body">
        <div className="content">
          {preview ? (
            <PowerInvoice
              key={selectedUnits[0].id}
              data={selectedUnits[0]}
              formData={formData}
              number={currentYearInvoiceCount[selectedUnits[0].id] + 1}
            />
          ) : (
            <>
              <UnitDetails units={units} data={data} />
              <hr />
              <label>
                <strong>Título:</strong>{' '}
                <input
                  className="input is-small"
                  type="text"
                  value={formData.title}
                  onChange={(e) => {
                    setFormData((prev) => {
                      let newFormData = { ...prev };
                      newFormData.title = e.target.value;
                      return newFormData;
                    });
                  }}
                />
              </label>
              <ul style={{ listStyle: 'none', marginLeft: 0 }}>
                {formData.rows.map((row, index) => (
                  <li key={index}>
                    <hr />
                    <label>
                      <strong>Referência:</strong>
                      <input
                        className="input is-small"
                        type="text"
                        value={row.ref}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].ref = e.target.value;
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Leitura anterior:</strong>
                      <input
                        className="input is-small"
                        placeholder="xx.xx"
                        value={row.previousReading}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].previousReading =
                              e.target.value.replaceAll(',', '.');
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Data anterior:</strong>
                      <input
                        className="input is-small"
                        type="date"
                        value={row.previousDate}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].previousDate =
                              e.target.value;
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Leitura actual:</strong>
                      <input
                        className="input is-small"
                        placeholder="xx.xx"
                        value={row.currentReading}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].currentReading =
                              e.target.value.replaceAll(',', '.');
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Data actual:</strong>
                      <input
                        className="input is-small"
                        type="date"
                        value={row.currentDate}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].currentDate =
                              e.target.value;
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Valor unitário:</strong>
                      <input
                        className="input is-small"
                        value={row.unitValue}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].unitValue = e.target.value;
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                    <label>
                      <strong>Observações:</strong>
                      <input
                        className="input is-small"
                        type="text"
                        value={row.observations}
                        onChange={(e) => {
                          setFormData((prev) => {
                            let newFormData = { ...prev };
                            newFormData.rows[index].observations =
                              e.target.value;
                            return newFormData;
                          });
                        }}
                      />
                    </label>
                  </li>
                ))}
              </ul>
              <div className="buttons">
                <button
                  className="button is-primary"
                  onClick={() => {
                    setFormData((prev) => {
                      let newFormData = { ...prev };
                      const newRow = { ...rowObject };
                      newFormData.rows.push(newRow);
                      return newFormData;
                    });
                  }}
                >
                  Adicionar linha
                </button>
                <button
                  disabled={formData.rows.length === 1}
                  className="button is-danger"
                  onClick={() => {
                    setFormData((prev) => {
                      let newFormData = { ...prev };
                      newFormData.rows.pop();
                      return newFormData;
                    });
                  }}
                >
                  Remover a última linha
                </button>
              </div>
            </>
          )}
        </div>
      </section>
      <footer className="modal-card-foot">
        {preview ? (
          <>
            <button
              className={`button is-success ${saving ? 'is-loading' : ''}`}
              onClick={() => {
                setSaving(true);
                let generatedPDFS = [];
                selectedUnits.forEach((selectedUnit) => {
                  const invoice = generatePowerInvoice(
                    selectedUnit,
                    formData,
                    currentYearInvoiceCount[selectedUnit.id] + 1
                  );
                  save({
                    data: {
                      data: {
                        Date: new Date().toISOString().split('T')[0],
                        Data: {
                          type: 'power',
                          data: selectedUnit,
                          formData,
                          ...invoice,
                        },
                        unit: selectedUnit.id,
                      },
                    },
                  }).then(() => {
                    pdfMake.createPdf(invoice.pdfMakeObject).getBlob((blob) => {
                      generatedPDFS.push({
                        filename: `invoice_${
                          selectedUnit.attributes.Identifier
                        }_${new Date().toISOString().split('T')[0]}.pdf`,
                        blob,
                      });
                      if (generatedPDFS.length === selectedUnits.length) {
                        const zip = new JSZip();
                        generatedPDFS.forEach((pdf) => {
                          zip.file(pdf.filename, pdf.blob);
                        });
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                          setVisible();
                          setSaving(false);
                          saveAs(content, 'download.zip');
                          window.location.reload();
                        });
                      }
                    });
                  });
                });
              }}
            >
              Gravar
            </button>
            <button
              className="button"
              disabled={saving}
              onClick={() => {
                setPreview(false);
              }}
            >
              Voltar
            </button>
          </>
        ) : (
          <>
            <button
              disabled={!isValid}
              className="button is-success"
              onClick={() => {
                setPreview(true);
              }}
            >
              Pré-visualizar
            </button>
            <button
              className="button"
              onClick={() => {
                setVisible();
              }}
            >
              Cancelar
            </button>
          </>
        )}
      </footer>
    </>
  );
};

export default PowerInvoiceForm;
