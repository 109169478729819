import { useMemo } from 'react';

const UnitDetails = ({ units, data }) => {
  const selectedUnits = useMemo(() => {
    const selectedUnitsObject = units.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
    return data?.length
      ? data?.filter((item) => selectedUnitsObject[item.id])
      : [data];
  }, [units, data]);

  return (
    <>
      {units.length === 1 ? (
        <dl>
          <dt>
            <strong>Fracção:</strong>
          </dt>
          <dd>{data.attributes.Identifier}</dd>
          <dt>
            <strong>Designação:</strong>
          </dt>
          <dd>{data.attributes.Designation}</dd>
          <dt>
            <strong>Proprietário(s):</strong>
          </dt>
          <dd>
            {data.attributes.owners.data.map((owner) => (
              <div key={owner.id}>
                {owner.attributes.Name}{' '}
                {owner.attributes.Emails?.length > 0 && (
                  <>({owner.attributes.Emails.join()})</>
                )}
              </div>
            ))}
          </dd>
          {data.attributes.contacts.data.length > 0 && (
            <>
              <dt>
                <strong>Contacto(s):</strong>
              </dt>
              <dd>
                {data.attributes.contacts.data.map((contact) => (
                  <div key={contact.id}>
                    {contact.attributes.Name}{' '}
                    {contact.attributes.Emails.length > 0 && (
                      <>({contact.attributes.Emails.join()})</>
                    )}
                  </div>
                ))}
              </dd>
            </>
          )}
        </dl>
      ) : (
        <>
          <strong>Fracções seleccionadas:</strong>
          <p>
            {selectedUnits.map((unit) => unit.attributes.Identifier).join(', ')}
          </p>
        </>
      )}
    </>
  );
};

export default UnitDetails;
