import { useState, useMemo } from 'react';

import useRequest from 'utils/useRequest';

const PaymentModal = ({
  unit: { id: unit, unit: code, owners },
  setVisible,
}) => {
  const [invoiceType, setInvoiceType] = useState('common');
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    owner: owners[0].id,
    date: '',
    value: '',
    notes: '',
  });

  const { request: save } = useRequest({
    url: `invoices`,
    method: 'POST',
    callback: true,
  });

  const isValid = useMemo(() => {
    return (
      formData.date &&
      !(
        Number(formData.value).toString() !== formData.value &&
        Number(formData.value).toFixed(2).toString() !== formData.value
      )
    );
  }, [formData]);

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p
              className="modal-card-title"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingRight: '20px',
              }}
            >
              Adicionar pagamento{' '}
              <span className="field has-addons">
                <span className="control">
                  <button
                    className={`button ${
                      invoiceType === 'common' ? 'is-primary' : ''
                    }`}
                    onClick={() => {
                      setInvoiceType('common');
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-percent"></i>
                    </span>
                    <span>Quotas</span>
                  </button>
                </span>
                <span className="control">
                  <button
                    className={`button ${
                      invoiceType === 'power' ? 'is-primary' : ''
                    }`}
                    onClick={() => {
                      setInvoiceType('power');
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-plug"></i>
                    </span>
                    <span>Electricidade</span>
                  </button>
                </span>
              </span>
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setVisible();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">
              Fracção {code}
              <hr />
              <div className="field">
                <div className="select is-small">
                  <select
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        owner: e.target.value,
                      }));
                    }}
                  >
                    {owners.map((owner) => (
                      <option key={owner.id} value={owner.id}>
                        {owner.attributes.Name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="field" style={{ width: '160px' }}>
                <label className="label is-small">Data</label>
                <div className="control">
                  <input
                    className="input is-small"
                    type="date"
                    placeholder="DD/MM/YYYY"
                    value={formData.date}
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        date: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="field" style={{ width: '160px' }}>
                <label className="label is-small">Valor</label>
                <div className="control">
                  <input
                    className="input is-small"
                    type="text"
                    value={formData.value}
                    placeholder="xx.xx"
                    onChange={(e) => {
                      setFormData((prev) => ({
                        ...prev,
                        value: e.target.value.replaceAll(',', '.'),
                      }));
                    }}
                  />
                </div>
              </div>
              <div className="field">
                <label className="label is-small">Notas</label>
                <textarea
                  className="textarea"
                  onChange={(event) => {
                    setFormData((prev) => ({
                      ...prev,
                      notes: event.target.value,
                    }));
                  }}
                  defaultValue={formData.notes}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button
              className={`button is-success ${saving ? 'is-loading' : ''}`}
              disabled={!isValid}
              onClick={() => {
                setSaving(true);
                save({
                  url: `transactions`,
                  method: 'POST',
                  data: {
                    data: {
                      owner: formData.owner,
                      Date: formData.date,
                      Value: formData.value,
                      Notes: formData.notes,
                      Type: invoiceType,
                      unit,
                    },
                  },
                }).then(() => {
                  setVisible();
                  setSaving(true);
                  window.location.reload();
                });
              }}
            >
              Gravar
            </button>
            <button
              className="button"
              disabled={saving}
              onClick={() => {
                setVisible();
              }}
            >
              Voltar
            </button>
          </footer>
        </div>
      </div>
    </>
  );
};

export default PaymentModal;
