import { useState } from 'react';

import useRequest from 'utils/useRequest';
import Loading from 'components/Loading';

import CommonInvoiceForm from './InvoiceModal/CommonInvoiceForm';
import PowerInvoiceForm from './InvoiceModal/PowerInvoiceForm';

const InvoiceModal = ({ units, setVisible }) => {
  const [invoiceType, setInvoiceType] = useState('common');
  const [preview, setPreview] = useState(false);

  const { data: { data } = {}, loading } = useRequest({
    url:
      units.length === 1
        ? `units/${units[0]}?&populate=*`
        : `units?&populate=*`,
    method: 'GET',
  });

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(128, 125, 125, 0.7)',
          top: 0,
          left: 0,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p
            className="modal-card-title"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: '20px',
            }}
          >
            Criar aviso de pagamento{' '}
            {units.length === 1 && !preview && (
              <span className="field has-addons">
                <span className="control">
                  <button
                    className={`button ${
                      invoiceType === 'common' ? 'is-primary' : ''
                    }`}
                    onClick={() => {
                      setInvoiceType('common');
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-percent"></i>
                    </span>
                    <span>Quotas</span>
                  </button>
                </span>
                <span className="control">
                  <button
                    className={`button ${
                      invoiceType === 'power' ? 'is-primary' : ''
                    }`}
                    onClick={() => {
                      setInvoiceType('power');
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-plug"></i>
                    </span>
                    <span>Electricidade</span>
                  </button>
                </span>
              </span>
            )}
          </p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setVisible();
            }}
          />
        </header>

        {invoiceType === 'common' && (
          <CommonInvoiceForm
            units={units}
            setVisible={setVisible}
            data={data}
            preview={preview}
            setPreview={setPreview}
          />
        )}
        {invoiceType === 'power' && (
          <PowerInvoiceForm
            units={units}
            setVisible={setVisible}
            data={data}
            preview={preview}
            setPreview={setPreview}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceModal;
