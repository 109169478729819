import { useState, useMemo } from 'react';
import { capitalize } from 'lodash';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import useRequest from 'utils/useRequest';

import generateInvoice from '../utils/generateInvoice';
import CommonInvoice from './CommonInvoice';
import UnitDetails from './UnitDetails';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const quarters = {
  1: [1, 2, 3],
  2: [1, 2, 3],
  3: [1, 2, 3],
  4: [4, 5, 6],
  5: [4, 5, 6],
  6: [4, 5, 6],
  7: [7, 8, 9],
  8: [7, 8, 9],
  9: [7, 8, 9],
  10: [10, 11, 12],
  11: [10, 11, 12],
  12: [10, 11, 12],
};

export const months = Array.from({ length: 12 }, (_, index) => ({
  value: index + 1,
  name: capitalize(
    new Date(`1970-${(index + 1).toString().padStart(2, '0')}`).toLocaleString(
      'pt-PT',
      {
        month: 'long',
      }
    )
  ),
  nameEn: new Date(
    `1970-${(index + 1).toString().padStart(2, '0')}`
  ).toLocaleString('en', {
    month: 'long',
  }),
}));

const defaultFormData = {
  1: quarters[currentMonth].includes(1),
  2: quarters[currentMonth].includes(2),
  3: quarters[currentMonth].includes(3),
  4: quarters[currentMonth].includes(4),
  5: quarters[currentMonth].includes(5),
  6: quarters[currentMonth].includes(6),
  7: quarters[currentMonth].includes(7),
  8: quarters[currentMonth].includes(8),
  9: quarters[currentMonth].includes(9),
  10: quarters[currentMonth].includes(10),
  11: quarters[currentMonth].includes(11),
  12: quarters[currentMonth].includes(12),
  date: new Date().toLocaleDateString('pt-PT'),
  fix: false,
  ago: '',
  custom: false,
  customDescription: '',
  customValue: '',
};

const CommonInvoiceForm = ({
  units,
  setVisible,
  data,
  preview,
  setPreview,
}) => {
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  const { request: save } = useRequest({
    url: `invoices`,
    method: 'POST',
    callback: true,
  });

  const selectedUnits = useMemo(() => {
    const selectedUnitsObject = units.reduce((acc, item) => {
      acc[item] = true;
      return acc;
    }, {});
    return data?.length
      ? data?.filter((item) => selectedUnitsObject[item.id])
      : [data];
  }, [units, data]);

  const currentYearInvoiceCount = useMemo(
    () =>
      selectedUnits.reduce((acc, item) => {
        const invoices = item.attributes.invoices.data.filter(
          (invoice) =>
            invoice.attributes.Date.slice(0, 4) === currentYear.toString()
        );
        acc[item.id] = invoices.length;
        return acc;
      }, {}),
    [selectedUnits]
  );

  const selectedMonths = months.reduce((acc, item) => {
    if (formData[item.value]) {
      acc.push(item.value);
    }
    return acc;
  }, []);

  const validMonths =
    selectedMonths.length === 0 ||
    (selectedMonths.length > 0 &&
      selectedMonths[selectedMonths.length - 1] - selectedMonths[0] ===
        selectedMonths.length - 1);

  const validCustomParcel =
    !formData.custom ||
    (formData.custom &&
      (!(
        Number(formData.customValue).toString() !== formData.customValue &&
        Number(formData.customValue).toFixed(2).toString() !==
          formData.customValue
      ) ||
        (!formData.customValue && !formData.custom)) &&
      ((formData.custom && formData.customDescription) || !formData.custom));

  const isValid =
    validMonths &&
    validCustomParcel &&
    (selectedMonths.length > 0 || formData.custom);

  return (
    <>
      <section className="modal-card-body">
        <div className="content">
          {preview ? (
            <CommonInvoice
              key={selectedUnits[0].id}
              data={selectedUnits[0]}
              formData={formData}
              year={currentYear}
              number={currentYearInvoiceCount[selectedUnits[0].id] + 1}
            />
          ) : (
            <>
              <UnitDetails units={units} data={data} />
              <hr />
              <p>
                <strong>Parcelas a incluir:</strong>
              </p>
              <fieldset>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={formData.custom}
                    onChange={() => {
                      setFormData((prev) => ({
                        ...prev,
                        custom: !prev.custom,
                      }));
                    }}
                  />{' '}
                  Parcela customizável
                </label>
                {formData.custom && (
                  <>
                    <div style={{ width: '160px' }}>
                      <label className="label is-small">Descrição</label>
                      <div className="control">
                        <input
                          className="input is-small"
                          type="text"
                          value={formData.customDescription}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              customDescription: e.target.value,
                            }));
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ width: '160px' }}>
                      <label className="label is-small">Valor</label>
                      <div className="control">
                        <input
                          className="input is-small"
                          placeholder="xx.xx"
                          value={formData.customValue}
                          onChange={(e) => {
                            setFormData((prev) => ({
                              ...prev,
                              customValue: e.target.value.replaceAll(',', '.'),
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </fieldset>
              <fieldset disabled={selectedMonths[0] === 1}>
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={formData.fix}
                    onChange={() => {
                      setFormData((prev) => ({
                        ...prev,
                        fix: !prev.fix,
                      }));
                    }}
                  />{' '}
                  Acertos dos meses anteriores aos seleccionados
                </label>
                {formData.fix && (
                  <div style={{ width: '160px' }}>
                    <label className="label is-small">Data da A.G.O.</label>
                    <div className="control has-icons-left">
                      <input
                        className="input is-small"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        value={formData.ago}
                        onChange={(e) => {
                          setFormData((prev) => ({
                            ...prev,
                            ago: e.target.value,
                          }));
                        }}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-calendar"></i>
                      </span>
                    </div>
                  </div>
                )}
              </fieldset>
              {months.map((month) => (
                <div key={month.value}>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={formData[month.value]}
                      onChange={() => {
                        setFormData((prev) => ({
                          ...prev,
                          [month.value]: !prev[month.value],
                          fix:
                            month.value === 1 && !prev[month.value]
                              ? false
                              : prev.fix,
                        }));
                      }}
                    />{' '}
                    {month.name}
                  </label>
                </div>
              ))}
            </>
          )}
        </div>
      </section>
      <footer className="modal-card-foot">
        {preview ? (
          <>
            <button
              className={`button is-success ${saving ? 'is-loading' : ''}`}
              onClick={() => {
                setSaving(true);
                let generatedPDFS = [];
                selectedUnits.forEach((selectedUnit) => {
                  const invoice = generateInvoice(
                    selectedUnit,
                    formData,
                    currentYear,
                    currentYearInvoiceCount[selectedUnit.id] + 1
                  );
                  save({
                    data: {
                      data: {
                        Date: new Date().toISOString().split('T')[0],
                        Data: {
                          type: 'common',
                          data: selectedUnit,
                          formData,
                          year: currentYear,
                          ...invoice,
                        },
                        unit: selectedUnit.id,
                      },
                    },
                  }).then(() => {
                    pdfMake.createPdf(invoice.pdfMakeObject).getBlob((blob) => {
                      let scopeInit;
                      let scopeEnd;
                      for (let index = 1; index <= 12; index++) {
                        if (!scopeInit && formData[index]) {
                          scopeInit = index;
                        }
                        if (scopeInit && index > scopeInit && formData[index]) {
                          scopeEnd = index;
                        }
                      }
                      const language =
                        selectedUnit.attributes.owners.data[0].attributes
                          .Language;
                      let scope = '';
                      if (scopeInit) {
                        scopeInit =
                          language === 'PT'
                            ? months[scopeInit - 1].name
                            : months[scopeInit - 1].nameEn;
                        scopeEnd =
                          language === 'PT'
                            ? months[scopeEnd - 1].name
                            : months[scopeEnd - 1].nameEn;
                        scope = !scopeEnd
                          ? scopeInit.substring(0, 3)
                          : `${scopeInit.substring(0, 3)}-${scopeEnd.substring(
                              0,
                              3
                            )}`;
                      }
                      generatedPDFS.push({
                        filename: `${selectedUnit.attributes.Identifier}_${scope}_${currentYear}.pdf`,
                        blob,
                      });
                      if (generatedPDFS.length === selectedUnits.length) {
                        const zip = new JSZip();
                        generatedPDFS.forEach((pdf) => {
                          zip.file(pdf.filename, pdf.blob);
                        });
                        zip.generateAsync({ type: 'blob' }).then((content) => {
                          setVisible();
                          setSaving(false);
                          saveAs(content, 'download.zip');
                          window.location.reload();
                        });
                      }
                    });
                  });
                });
              }}
            >
              Gravar
            </button>
            <button
              className="button"
              disabled={saving}
              onClick={() => {
                setPreview(false);
              }}
            >
              Voltar
            </button>
          </>
        ) : (
          <>
            <button
              className="button is-success"
              onClick={() => {
                setPreview(true);
              }}
              disabled={!isValid}
            >
              Pré-visualizar
            </button>
            <button
              className="button"
              onClick={() => {
                setVisible();
              }}
            >
              Cancelar
            </button>
          </>
        )}
      </footer>
    </>
  );
};

export default CommonInvoiceForm;
