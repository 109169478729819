import { useRef, useEffect } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import generateInvoice from '../utils/generateInvoice';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const CommonInvoice = ({ data, formData, year, number }) => {
  const iframeContainer = useRef();

  useEffect(() => {
    const { pdfMakeObject: invoiceDefinition } = generateInvoice(
      data,
      formData,
      year,
      number
    );
    const pdfDocGenerator = pdfMake.createPdf(invoiceDefinition);
    pdfDocGenerator.getDataUrl((dataUrl) => {
      iframeContainer.current.innerHTML = '';
      const iframe = document.createElement('iframe');
      iframe.setAttribute('style', 'width: 100%; height: 800px');
      iframe.src = dataUrl;
      iframeContainer.current.appendChild(iframe);
    });
  }, [data, formData, year, number]);

  return <div ref={iframeContainer} />;
};

export default CommonInvoice;
