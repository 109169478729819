const generateReceipt = (movement, data) => {
  const language =
    movement.owner?.attributes.Language ||
    data.attributes.owners.data[0].attributes.Language;
  return {
    totalValue: movement.value,
    pdfMakeObject: {
      styles: {
        removePadding: {
          margin: [-5, 0, 0, 0],
        },
      },
      content: [
        {
          columns: [
            [
              {
                text: data.attributes.condo.data.attributes.Name,
              },
              {
                text: data.attributes.condo.data.attributes.Address,
              },
              {
                text:
                  language === 'PT'
                    ? `Contribuinte nº ${data.attributes.condo.data.attributes.VAT}`
                    : `VAT: ${data.attributes.condo.data.attributes.VAT}`,
              },
            ],
            movement.owner && [
              {
                text: `\n\n${movement.owner.attributes.Name}`,
              },
              {
                text: movement.owner.attributes.Address,
              },
            ],
          ].filter(Boolean),
        },
        {
          text: '\n\n',
        },
        {
          text:
            language === 'PT'
              ? `Recibo Nº ${movement.date.slice(0, 4)}/${
                  movement.invoiceNumber
                }${movement.revoked ? ` (ANULADO)` : ``}`
              : `Receipt No. ${movement.date.slice(0, 4)}/${
                  movement.invoiceNumber
                }${movement.revoked ? ` (REVOKED)` : ``}`,
          bold: true,
        },
        {
          margin: [0, 20, 0, 8],
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', '*', 'auto', 'auto'],
            body: [
              [
                {
                  text: language === 'PT' ? 'V/Nº Contrib.' : 'VAT number',
                  bold: true,
                },
                { text: language === 'PT' ? 'Fracção' : 'Unit', bold: true },
                {
                  text: language === 'PT' ? 'Designação' : 'Designation',
                  bold: true,
                },
                { text: language === 'PT' ? 'Data' : 'Date', bold: true },
                { text: 'Via', bold: true },
              ],
              [
                movement.owner?.attributes.VAT ? movement.owner.attributes.VAT : '-',
                data.attributes.Identifier,
                data.attributes.Designation,
                movement.date,
                'Original',
              ],
            ],
          },
        },
        {
          text: '\n\n',
        },
        {
          layout: 'lightHorizontalLines',
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: language === 'PT' ? 'Descrição' : 'Description',
                  bold: true,
                },
                { text: 'Total', bold: true },
              ],
              [
                {
                  text: movement.notes,
                },
                { text: `${movement.value.toFixed(2)} €` },
              ],
            ],
          },
        },
        {
          text: '\n',
        },
        {
          table: {
            body: [
              [
                {
                  border: [false, false, false, false],
                  text: language === 'PT' ? 'TOTAL PAGO:' : 'TOTAL AMOUNT:',
                  style: 'removePadding',
                  bold: true,
                },
                `${movement.value.toFixed(2)} €`,
              ],
            ],
          },
        },
        {
          text: '\n',
        },
        {
          text: [
            {
              text:
                language === 'PT'
                  ? 'Documento processado por computador\n\n'
                  : 'Document processed by computer\n\n',
              fontSize: 12,
            },
            {
              text:
                language === 'PT'
                  ? 'Isento de IVA Artigo 9º CIVA'
                  : 'VAT exempt Art. 9 CIVA',
              bold: true,
            },
          ],
        },
      ],
    },
  };
};

export default generateReceipt;
