import { Link } from 'react-router-dom';

import useRequest from 'utils/useRequest';
import Markdown from 'utils/Markdown';
import Loading from 'components/Loading';

const Home = () => {
  const { data, loading } = useRequest({
    url: 'condos',
    method: 'GET',
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">Condomínios</h1>
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Morada</th>
              <th>IBAN</th>
              <th>NIF</th>
            </tr>
          </thead>
          <tbody>
            {data.data.map(({ id, attributes: condo }) => (
              <tr key={id}>
                <th>
                  <Link to={`/condo/${id}/`}>{condo.Name}</Link>
                </th>
                <td>
                  <Markdown content={condo.Address} />
                </td>
                <td>{condo.IBAN}</td>
                <td>{condo.VAT}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Home;
