import { useEffect } from 'react';
import Cookies from 'js-cookie';

import useRequest from 'utils/useRequest';

const Login = () => {
  const {
    data,
    loading: submitting,
    error,
    request,
  } = useRequest({
    url: 'auth/local',
    method: 'POST',
    callback: true,
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    request({ data: new FormData(event.target) });
  };

  useEffect(() => {
    if (data) {
      Cookies.set('AUTH-TOKEN', data.jwt, {
        expires: 15,
        sameSite: 'strict',
      });
      window.location.reload();
    }
  }, [data]);

  return (
    <div className="section content">
      <div className="columns">
        <div className="column is-4-widescreen is-6-tablet">
          <form
            onSubmit={(event) => {
              event.persist();
              handleSubmit(event);
            }}
          >
            <div className="field">
              <label className="label">Nome de utilizador</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="text"
                  name="identifier"
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fa-fw fa-solid fa-user" />
                </span>
              </div>
            </div>
            <div className="field">
              <label className="label">Palavra-passe</label>
              <div className="control has-icons-left">
                <input
                  className="input"
                  type="password"
                  name="password"
                  required
                />
                <span className="icon is-small is-left">
                  <i className="fa-fw fa-solid fa-key" />
                </span>
              </div>
            </div>
            {error && (
              <div className="field">
                <p className="help is-danger">
                  Credenciais erradas (ou o servidor está em baixo 🥸)
                </p>
              </div>
            )}
            <div className="field is-grouped">
              <div className="control">
                <button
                  className={`button is-primary ${submitting && 'is-loading'}`}
                  disabled={submitting}
                >
                  Entrar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
