import marked from 'marked';

marked.setOptions({
  gfm: true,
  breaks: true,
});

const Markdown = ({ content }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: marked(content || ''),
    }}
  ></div>
);

export default Markdown;
