import { Routes, Route, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import logo from 'assets/logo.png';
import { useStateValue } from 'state/State';
import Auth from 'utils/Auth';
import Home from 'views/Home';
import Condo from 'views/Condo';
import NoMatch from 'views/NoMatch';

const App = () => {
  const [{ user }] = useStateValue();
  return (
    <>
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {user && (
          <div className="navbar-menu">
            <div className="navbar-start">
              <Link className="navbar-item" to="/">
                Condomínios
              </Link>
            </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  <button
                    className="button is-danger is-small"
                    to="/logout"
                    onClick={() => {
                      Cookies.remove('AUTH-TOKEN');
                      window.location.reload();
                    }}
                  >
                    Sair
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </nav>
      <main className="section">
        <Auth>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/condo/:id" element={<Condo />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Auth>
      </main>
    </>
  );
};

export default App;
