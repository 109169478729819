import { useMemo, useState } from 'react';

import useRequest from 'utils/useRequest';

const currentYear = new Date().getFullYear();

const QuotesModal = ({ units, setVisible }) => {
  const rates = useMemo(
    () =>
      units.map((unit) => {
        const rate = unit.attributes.rates.data.find(
          (rate) => rate.attributes.Year === currentYear
        );
        if (rate) {
          return {
            id: rate.id,
            unitId: unit.id,
            value: rate.attributes.Value,
          };
        }
        return undefined;
      }),
    [units]
  );

  const hasRates = useMemo(() => rates.some((rate) => rate?.value), [rates]);

  const [tempRates, setTempRates] = useState(
    hasRates
      ? rates
          .map((rate, index) =>
            index === rates.length - 1
              ? `${rate?.value || ''}`
              : `${rate?.value || ''}\n`
          )
          .join('')
      : undefined
  );

  const isValid = useMemo(() => {
    if (!tempRates) {
      return false;
    }
    const newRates = tempRates.split(/\r?\n/);
    if (newRates.length !== units.length) {
      return false;
    }
    if (
      newRates.some(
        (rate) =>
          Number(rate).toString() !== rate &&
          Number(rate).toFixed(2).toString() !== rate
      )
    ) {
      return false;
    }
    return true;
  }, [units, tempRates]);

  const { loading: requesting, request } = useRequest({
    callback: true,
  });

  const saveRates = async (newRates) => {
    for (let index = 0; index < rates.length; index++) {
      if (rates[index]) {
        await request({
          url: `rates/${rates[index].id}`,
          method: 'DELETE',
        });
      }
    }
    const ratesToSave = newRates.split(/\r?\n/);
    for (let index = 0; index < units.length; index++) {
      await request({
        url: `rates`,
        method: 'POST',
        data: {
          data: {
            Year: currentYear,
            Value: ratesToSave[index],
            unit: units[index].id,
          },
        },
      });
    }
    window.location.reload();
  };

  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Editar quotas</p>
          <button
            className="delete"
            disabled={requesting}
            aria-label="close"
            onClick={() => {
              setVisible(false);
            }}
          />
        </header>
        <section className="modal-card-body">
          <div className="content">
            <p>As quotas introduzidas afectarão apenas o ano actual ({currentYear})</p>
            <textarea
              className="textarea"
              placeholder={'Exemplo:\n99.99\n49.99\n89.99'.replace(
                /\\n/g,
                '\n'
              )}
              onChange={(event) => {
                setTempRates(event.target.value.replaceAll(',', '.'));
              }}
              defaultValue={tempRates}
            />
          </div>
        </section>
        <footer className="modal-card-foot">
          <button
            className={`button is-success ${requesting ? 'is-loading' : ''}`}
            onClick={() => {
              saveRates(tempRates);
            }}
            disabled={!isValid || requesting}
          >
            Gravar
          </button>
          <button
            className="button"
            disabled={requesting}
            onClick={() => {
              setVisible(false);
            }}
          >
            Cancelar
          </button>
        </footer>
      </div>
    </div>
  );
};

export default QuotesModal;
