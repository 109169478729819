import { useState, useMemo } from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import generateInvoice from 'views/Condo/utils/generateInvoice';
import generatePowerInvoice from 'views/Condo/utils/generatePowerInvoice';
import generateReceipt from 'views/Condo/utils/generateReceipt';
import useRequest from 'utils/useRequest';
import Markdown from 'utils/Markdown';
import Loading from 'components/Loading';

import DeleteModal from './DeleteModal';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const MovementsModal = ({
  unit: { id: unit, unit: code },
  setVisible,
  onChange,
}) => {
  const [paymentDetail, setPaymentDetail] = useState();
  const [deleteModal, setDeleteModal] = useState();

  const {
    request: reload,
    data: { data } = {},
    loading,
  } = useRequest({
    url: `units/${unit}?populate[transactions][populate][owner]=*&populate[invoices]=*&populate[owners]=*&populate[condo]=*`,
    method: 'GET',
  });

  const { request: requestDelete, loading: deleting } = useRequest({
    callback: true,
  });

  const movements = useMemo(() => {
    if (data) {
      let year;
      let invoiceNumber = 1;
      const invoices = data.attributes.invoices.data.map((invoice) => {
        if (year !== invoice.attributes.Date.slice(0, 4)) {
          invoiceNumber = 1;
        } else {
          invoiceNumber += 1;
        }
        year = invoice.attributes.Date.slice(0, 4);
        return {
          id: `invoice-${invoice.id}`,
          sourceId: invoice.id,
          type: 'invoice',
          internalType: invoice.attributes.Data.type,
          date: invoice.attributes.Date,
          value: invoice.attributes.Data.totalValue,
          Data: invoice.attributes.Data,
          invoiceNumber,
          revoked: invoice.attributes.Revoked,
        };
      });
      year = undefined;
      invoiceNumber = 1;
      const transactions = data.attributes.transactions.data.map(
        (transaction) => {
          if (year !== transaction.attributes.Date.slice(0, 4)) {
            invoiceNumber = 1;
          } else {
            invoiceNumber += 1;
          }
          year = transaction.attributes.Date.slice(0, 4);
          return {
            id: `transaction-${transaction.id}`,
            sourceId: transaction.id,
            type: 'transaction',
            internalType: transaction.attributes.Type,
            date: transaction.attributes.Date,
            value: transaction.attributes.Value,
            notes: transaction.attributes.Notes,
            revoked: transaction.attributes.Revoked,
            owner: transaction.attributes.owner?.data,
            invoiceNumber,
          };
        }
      );
      return invoices
        .concat(transactions)
        .sort((a, b) => new Date(b.date) > new Date(a.date));
    }
    return [];
  }, [data]);

  if (loading) {
    return (
      <div
        style={{
          position: 'fixed',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(128, 125, 125, 0.7)',
          top: 0,
          left: 0,
          zIndex: 1000,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Movimentos (Fracção {code})</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setVisible();
              }}
            />
          </header>
          <section className="modal-card-body">
            <div className="content">
              {paymentDetail ? (
                <>
                  <button
                    className="button is-small"
                    onClick={() => {
                      setPaymentDetail();
                    }}
                  >
                    <span className="icon">
                      <i className="fas fa-arrow-left"></i>
                    </span>
                    <span>Voltar aos movimentos</span>
                  </button>
                  <br />
                  <br />
                  <div>
                    <p>
                      <strong className="is-size-5 is-underlined">
                        Detalhe do pagamento
                      </strong>
                    </p>
                    <dl>
                      {paymentDetail.owner && (
                        <>
                          <dt>
                            <strong>Proprietário:</strong>
                          </dt>
                          <dd>{paymentDetail.owner.attributes.Name}</dd>
                        </>
                      )}
                      <dt>
                        <strong>Data:</strong>
                      </dt>
                      <dd>{paymentDetail.date}</dd>
                      <dt>
                        <strong>Valor:</strong>
                      </dt>
                      <dd>{paymentDetail.value.toFixed(2)} €</dd>
                      <dt>
                        <strong>Notas:</strong>
                      </dt>
                      <dd>
                        <Markdown content={paymentDetail.notes} />
                      </dd>
                    </dl>
                  </div>
                  <div className="mt-4">
                    <p className="control">
                      <button
                        className="button is-small"
                        onClick={() => {
                          let invoiceDefinition = generateReceipt(
                            paymentDetail,
                            data
                          ).pdfMakeObject;
                          pdfMake.createPdf(invoiceDefinition).open();
                        }}
                      >
                        <span className="icon is-small">
                          <i className="fas fa-eye"></i>
                        </span>
                        <span>PDF</span>
                      </button>
                    </p>
                  </div>
                </>
              ) : (
                <>
                  {movements.length > 0 ? (
                    <table>
                      <thead>
                        <tr>
                          <th>Data</th>
                          <th>Tipo</th>
                          <th>Valor</th>
                          <td>&nbsp;</td>
                        </tr>
                      </thead>
                      <tbody>
                        {movements.map((movement) => (
                          <tr
                            key={movement.id}
                            style={
                              movement.revoked
                                ? {
                                    textDecoration: 'line-through',
                                    color: 'red',
                                  }
                                : undefined
                            }
                          >
                            <td>{movement.date}</td>
                            <td>
                              {movement.internalType === 'power' ? (
                                <i className="fas fa-plug"></i>
                              ) : (
                                <i className="fas fa-percent"></i>
                              )}{' '}
                              {movement.type === 'invoice'
                                ? 'Aviso de pagamento'
                                : 'Pagamento'}
                            </td>
                            <td>{movement.value?.toFixed(2)} €</td>
                            <td>
                              {movement.type === 'invoice' ? (
                                <div className="field has-addons">
                                  <p className="control">
                                    <button
                                      className="button is-small"
                                      onClick={() => {
                                        let invoiceDefinition;
                                        switch (movement.Data.type) {
                                          case 'power':
                                            invoiceDefinition =
                                              generatePowerInvoice(
                                                movement.Data.data,
                                                movement.Data.formData,
                                                movement.invoiceNumber,
                                                movement.revoked
                                              ).pdfMakeObject;
                                            break;
                                          default:
                                            invoiceDefinition = generateInvoice(
                                              movement.Data.data,
                                              movement.Data.formData,
                                              movement.Data.year,
                                              movement.invoiceNumber,
                                              movement.revoked
                                            ).pdfMakeObject;
                                            break;
                                        }
                                        pdfMake
                                          .createPdf(invoiceDefinition)
                                          .open();
                                      }}
                                    >
                                      <span className="icon is-small">
                                        <i className="fas fa-eye"></i>
                                      </span>
                                    </button>
                                  </p>
                                  <p className="control">
                                    <button
                                      className="button is-small is-danger"
                                      disabled={movement.revoked}
                                      onClick={() => {
                                        setDeleteModal({
                                          title: 'Anular aviso de pagamento',
                                          text: 'Tens a certeza que queres anular este aviso de pagamento?',
                                          onCall: () => {
                                            requestDelete({
                                              url: `invoices/${movement.sourceId}`,
                                              method: 'PUT',
                                              data: { data: { Revoked: 1 } },
                                            }).then(() => {
                                              setDeleteModal();
                                              reload();
                                              onChange();
                                            });
                                          },
                                        });
                                      }}
                                    >
                                      <span className="icon is-small">
                                        <i className="fas fa-trash"></i>
                                      </span>
                                    </button>
                                  </p>
                                </div>
                              ) : (
                                <div className="field has-addons">
                                  <p className="control">
                                    <button
                                      className="button is-small"
                                      onClick={() => {
                                        setPaymentDetail(movement);
                                      }}
                                    >
                                      <span className="icon is-small">
                                        <i className="fas fa-note-sticky"></i>
                                      </span>
                                    </button>
                                  </p>
                                  <p className="control">
                                    <button
                                      className="button is-small is-danger"
                                      disabled={movement.revoked}
                                      onClick={() => {
                                        setDeleteModal({
                                          title: 'Anular pagamento',
                                          text: 'Tens a certeza que queres anular este pagamento?',
                                          onCall: () => {
                                            requestDelete({
                                              url: `transactions/${movement.sourceId}`,
                                              method: 'PUT',
                                              data: { data: { Revoked: 1 } },
                                            }).then(() => {
                                              setDeleteModal();
                                              reload();
                                              onChange();
                                            });
                                          },
                                        });
                                      }}
                                    >
                                      <span className="icon is-small">
                                        <i className="fas fa-trash"></i>
                                      </span>
                                    </button>
                                  </p>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>Sem resultados</p>
                  )}
                </>
              )}
            </div>
          </section>
        </div>
      </div>
      {deleteModal && (
        <DeleteModal
          title={deleteModal.title}
          text={deleteModal.text}
          deleting={deleting}
          onCall={deleteModal.onCall}
          onClose={() => {
            setDeleteModal();
          }}
        />
      )}
    </>
  );
};

export default MovementsModal;
