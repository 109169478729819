const DeleteModal = ({
  onCall = () => {},
  onClose = () => {},
  title = '',
  text = '',
  deleting = false,
}) => (
  <div className="modal is-active">
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
        <button
          className="delete"
          aria-label="close"
          disabled={deleting}
          onClick={() => {
            onClose();
          }}
        />
      </header>
      <section className="modal-card-body">
        <div className="content">{text}</div>
      </section>
      <footer className="modal-card-foot">
        <button
          className={`button is-danger ${deleting && 'is-loading'}`}
          disabled={deleting}
          onClick={() => {
            onCall();
          }}
        >
          Sim
        </button>
        <button
          className="button"
          disabled={deleting}
          onClick={() => {
            onClose();
          }}
        >
          Não
        </button>
      </footer>
    </div>
  </div>
);

export default DeleteModal;
